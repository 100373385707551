.site-header{
    transition: all 0.3s ease;
    &.retract {
        transition: all 0.3s ease;
    }

    a{
        font-size: 1.25rem;
        font-weight: 500;
    }
}
